import React, { Component } from "react";
import PropTypes from "prop-types";
import { withNamespaces } from "react-i18next";
import { StyleSheet, css } from "aphrodite";
import chroma from "chroma-js";
import { compose } from "recompose";
import { Breakpoints, MediaQueries } from "inno-components";
import { Link as ScrollLink } from "react-scroll";
import cn from "classnames";
import $ from "jquery";
import Img from "react-image";
import Config from "../../Config";

import Link from "../Link";
import pagesInfos from "../../config/i18n/pagesInfos";
import ScrollPosition from "../utils/ScrollPosition";
import Languages, { getLanguage } from "./Languages";

const offset = 0;

const styles = StyleSheet.create({
  active: {
    color: Config.colors.secondary
  },
  link: {
    cursor: "pointer",
    color: "#fff",
    ":hover": {
      color: Config.colors.secondary
    },
    [MediaQueries.small]: {
      fontSize: "1.1em",
      fontWeight: "bold",
      color: Config.colors.primary
    }
  },
  navbarCollapse: {
    display: "flex",
    width: "auto",
    justifyContent: "flex-end"
  },
  header: {
    [MediaQueries.medium]: {
      display: "flex",
      justifyContent: "space-between",
      alignItem: "center"
    }
  },
  menuIcon: {
    display: "static",
    [MediaQueries.medium]: {
      display: "none"
    }
  },
  menuDesktop: {
    display: "none",
    [MediaQueries.medium]: {
      display: "flex",
      alignItems: "center",
      marginBottom: 0,
      marginRight: "50px"
    }
  },
  itemMenuDesktop: {
    display: "inline",
    margin: "0 1em",
    color: Config.colors.primary
  }
});

const LinkComponent = link => (
  <li>
    <Link
      page={pagesInfos.ComponentIndex}
      className={cn("click-close", css(styles.link))}
      query={`#${link.name}`}
    >
      {link.label}
    </Link>
  </li>
);

const ScrollLinkComponent = link => (
  <ScrollLink
    to={link.name}
    className={cn("click-close", css(styles.link))}
    spy
    activeClass={css(styles.active)}
    smooth
    offset={offset}
  >
    {link.label}
  </ScrollLink>
);

class Menu extends Component {
  state = {
    open: false
  };
  onToggleMenu = () => {
    this.setState({
      open: !this.state.open
    });
    this.setState({
      open: !this.state.open
    });
  };

  componentDidMount() {}

  render() {
    const { isDisplayFromHome } = this.props;

    const AccueilLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: "accueil",
          label: "Accueil"
        })
      : LinkComponent({
          name: "accueil",
          label: "Accueil"
        });
    const EcoldeDeTennisLink = (
      <Link
        page={pagesInfos.ComponentEcoleDeTennis}
        className={cn("click-close", css(styles.link))}
      >
        École de tennis
      </Link>
    );
    const TennisEteLink = (
      <Link
        page={pagesInfos.ComponentTennisEte}
        className={cn("click-close", css(styles.link))}
      >
        Tennis d'été
      </Link>
    );
    const TennisCouvertLink = (
      <Link
        page={pagesInfos.ComponentTennisCouvert}
        className={cn("click-close", css(styles.link))}
      >
        Tennis couvert
      </Link>
    );
    const NotreEquipeLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: "notre-equipe",
          label: "Notre équipe"
        })
      : LinkComponent({
          name: "notre-equipe",
          label: "Notre équipe"
        });
    const ContactLink = isDisplayFromHome
      ? ScrollLinkComponent({
          name: "contact",
          label: "Contact"
        })
      : LinkComponent({
          name: "contact",
          label: "Contact"
        });

    return (
      <header className="main-header">
        <div className="container-fluid">
          <div className={cn("box-header", css(styles.header))}>
            <div className="box-logo">
              <Link
                page={pagesInfos.ComponentIndex}
                className={cn("click-close", css(styles.link))}
              >
                <Img src="assets/img/logo.png" width="180" alt="Logo" />
              </Link>
            </div>

            <ul className={css(styles.menuDesktop)}>
              <li className={css(styles.itemMenuDesktop)}>{AccueilLink}</li>
              <li className={css(styles.itemMenuDesktop)}>
                {EcoldeDeTennisLink}
              </li>
              <li className={css(styles.itemMenuDesktop)}>{TennisEteLink}</li>
              <li className={css(styles.itemMenuDesktop)}>
                {TennisCouvertLink}
              </li>
              <li className={css(styles.itemMenuDesktop)}>{NotreEquipeLink}</li>
              <li className={css(styles.itemMenuDesktop)}>{ContactLink}</li>
            </ul>

            <a
              className={cn("box-primary-nav-trigger", css(styles.menuIcon))}
              href="javascript:void(0);"
            >
              <span className="box-menu-icon" />
            </a>
          </div>

          <nav>
            <ul className="box-primary-nav">
              <li className="box-label">Navigation</li>
              <li>{AccueilLink}</li>
              <li>{EcoldeDeTennisLink}</li>
              <li>{TennisEteLink}</li>
              <li>{TennisCouvertLink}</li>
              <li>{NotreEquipeLink}</li>
              <li>{ContactLink}</li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

const enhance = compose(
  withNamespaces("home"),
  ScrollPosition
);

Menu.propTypes = {
  isDisplayFromHome: PropTypes.bool
};

Menu.defaultProps = {
  isDisplayFromHome: true
};

export default enhance(Menu);
