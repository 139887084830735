import React, { Component } from "react";
import PropTypes from "prop-types";
import Scroll from "react-scroll";
import Img from "react-image";
import { StyleSheet, css } from "aphrodite";
import { withNamespaces } from "react-i18next";
import { StaticQuery, graphql } from "gatsby";
import { Link as ScrollLink } from "react-scroll";

import cn from "classnames";

import Link from "../Link";
import Config from "../../Config";
import Classes from "../../styles/classes";
import pagesInfos from "../../config/i18n/pagesInfos";
import Contact from "./Contact";
import Map from "./Map";

const offset = 0;

const styles = StyleSheet.create({
  footer: {
    backgroundColor: "#002337"
  },
  copy: {
    backgroundColor: "#002337"
  },
  link: {
    cursor: "pointer",
    color: "inherit"
  }
});

const LinkComponent = link => (
  <Link
    page={pagesInfos.ComponentIndex}
    className={cn("click-close", css(styles.link))}
    query={`#${link.name}`}
  >
    {link.label}
  </Link>
);

const ScrollLinkComponent = link => (
  <ScrollLink
    to={link.name}
    className={cn("click-close", css(styles.link))}
    spy
    activeClass={css(styles.active)}
    smooth
    offset={offset}
  >
    {link.label}
  </ScrollLink>
);

const Footer = ({ map, contact, isDisplayFromHome }) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        services: allContentfulServices(
          filter: { node_locale: { eq: "fr" } }
          sort: { fields: [position], order: ASC }
        ) {
          edges {
            node {
              id
              titre
              permalink
              position
              description {
                description
              }
            }
          }
        }
        produits: allContentfulProduits(
          filter: { node_locale: { eq: "fr" } }
          sort: { fields: [position], order: ASC }
        ) {
          edges {
            node {
              id
              titre
              permalink
              position
              description {
                description
              }
              service {
                id
              }
            }
          }
        }
      }
    `}
    render={data => {
      const AccueilLink = isDisplayFromHome
        ? ScrollLinkComponent({
            name: "accueil",
            label: "Accueil"
          })
        : LinkComponent({
            name: "accueil",
            label: "Accueil"
          });
      const EcoldeDeTennisLink = (
        <Link
          page={pagesInfos.ComponentEcoleDeTennis}
          className={cn("click-close", css(styles.link))}
        >
          École de tennis
        </Link>
      );
      const TennisEteLink = (
        <Link
          page={pagesInfos.ComponentTennisEte}
          className={cn("click-close", css(styles.link))}
        >
          Tennis d'été
        </Link>
      );
      const TennisCouvertLink = (
        <Link
          page={pagesInfos.ComponentTennisCouvert}
          className={cn("click-close", css(styles.link))}
        >
          Tennis couvert
        </Link>
      );
      const NotreEquipeLink = isDisplayFromHome
        ? ScrollLinkComponent({
            name: "notre-equipe",
            label: "Notre équipe"
          })
        : LinkComponent({
            name: "notre-equipe",
            label: "Notre équipe"
          });
      const ContactLink = isDisplayFromHome
        ? ScrollLinkComponent({
            name: "contact",
            label: "Contact"
          })
        : LinkComponent({
            name: "contact",
            label: "Contact"
          });
      return (
        <footer
          className="main-footer"
          style={{
            textAlign: "center"
          }}
        >
          <svg
            preserveAspectRatio="none"
            viewBox="0 0 100 102"
            height="100"
            width="100%"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className="svgcolor-light"
            style={{
              zIndex: 0
            }}
          >
            <path d="M0 0 L50 100 L100 0 Z" fill="#fff" stroke="#fff" />
          </svg>
          <div className="container">
            <div className="row mt-100 mb-50 footer-widgets">
              <div className="col-md-4 col-xs-12">
                <div className="footer-widget contact-widget">
                  <div className={css(Classes.center)}>
                    <Img
                      src="/assets/img/logo-blanc.png"
                      className="logo-footer img-responsive"
                      alt="Footer Logo"
                      style={{ maxWidth: "200px" }}
                    />
                  </div>
                  <p>
                    Rue Edith Cavell 92, <br />
                    BE-1180 Bruxelles <br />
                    Belgique
                  </p>
                  <div className={css(Classes.center)}>
                    <ul className="social-icons">
                      <li>
                        <a
                          className="facebook"
                          href="https://www.facebook.com/TennisClubChurchillUccle/"
                          rel="noopener noreferrer"
                        >
                          <i className="fa fa-facebook" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="footer-widget twitter-widget">
                  <h4>Contact</h4>
                  ASBL Tennis Club Uccle Churchill
                  <br />
                  Email: tennischurchill1180@gmail.com
                  <br />
                  Tél: +32 2 343 20 64
                  <br />
                  Tél: +32 473 78 56 80
                  <br />
                  N° entreprise : 0459 792 173
                  <br />
                  RPM Tribunal Entr. Fr. Bruxelles
                  <br />
                  Compte bancaire : BE26 3101 2376 6329
                  <br />
                </div>
              </div>

              <div className="col-md-4 col-xs-12">
                <div className="footer-widget company-links">
                  <h4>Menu</h4>
                  <ul className="footer-links">
                    <li>{AccueilLink}</li>
                    <li>{EcoldeDeTennisLink}</li>
                    <li>{TennisEteLink}</li>
                    <li>{TennisCouvertLink}</li>
                    <li>{NotreEquipeLink}</li>
                    <li>{ContactLink}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      );
    }}
  />
);

Footer.propTypes = {
  map: PropTypes.bool,
  contact: PropTypes.bool
};

Footer.defaultProps = {
  map: false,
  contact: false
};

export default withNamespaces("home")(Footer);
