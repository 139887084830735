import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import * as loadScript from "simple-load-script";
import "babel-polyfill"; // must be imported for async/await to work, see e.g. https:/github.com/gatsbyjs/gatsby/pull/3369#issuecomment-354599985

import CssBaseline from "@material-ui/core/CssBaseline";

class Layout extends React.Component {
  async componentDidMount() {
    // load the two JS libraries into `body`

    await loadScript("/assets/js/jquery-2.2.3.min.js", { inBody: true });

    await loadScript("/assets/js/animated-headline.js", { inBody: true });
    await loadScript("/assets/js/menu.js", { inBody: true });
    await loadScript("/assets/js/modernizr.js", { inBody: true });

    await loadScript("/assets/js/jquery.animsition.min.js", { inBody: true });

    await loadScript("/assets/js/wow.min.js", { inBody: true });

    await loadScript("/assets/js/init.js", { inBody: true });
    await loadScript("/assets/js/main.js", { inBody: true });
  }

  render() {
    const { children } = this.props;
    return (
      <>
        <CssBaseline />
        <Helmet
          title="Tennis Club Uccle Churchill - Club, école et cours de tennis - Bruxelles"
          meta={[
            {
              name: "description",
              content:
                "Découvrez notre club de tennis, convivial et familial, situé à Uccle, Bruxelles"
            },
            { name: "charset", content: "UTF-8" },
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1, shrink-to-fit=no"
            }
          ]}
        >
          <link
            rel="stylesheet"
            href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.1/css/font-awesome.min.css"
          />
          <link
            rel="stylesheet"
            href="https://cdn.linearicons.com/free/1.0.0/icon-font.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>
        <span className="frame-line top-frame visible-lg" />
        <span className="frame-line right-frame visible-lg" />
        <span className="frame-line bottom-frame visible-lg" />
        <span className="frame-line left-frame visible-lg" />
        {children}
      </>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
