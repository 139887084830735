module.exports = {
  ComponentIndex: {
    id: 'ComponentIndex',
    namespace: 'home',
    path: {
      fr: '/',
      en: '/en/'
    }
  },
  ComponentTennisEte: {
    id: 'ComponentTennisEte',
    namespace: 'home',
    path: {
      fr: '/tennis-ete',
      en: '/en/blog'
    }
  },
  ComponentTennisCouvert: {
    id: 'ComponentTennisCouvert',
    namespace: 'home',
    path: {
      fr: '/tennis-couvert',
      en: '/en/team'
    }
  },
  ComponentEcoleDeTennis: {
    id: 'ComponentEcoleDeTennis',
    namespace: 'home',
    path: {
      fr: '/ecole-de-tennis',
      en: '/en/team'
    }
  }
}
