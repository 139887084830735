import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

const styles = StyleSheet.create({
  container: {
    display: 'block',
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    borderRadius: '25px',
    marginTop: '4em',
    height: '350px'
  }
})

export class MapContainer extends Component {
  render() {
    const { google } = this.props
    return (
      <div className={css(styles.container)}>
        <Map
          google={google}
          zoom={14}
          initialCenter={{
            lat: 50.8121042,
            lng: 4.3540488999999525
          }}
        >
          <Marker
            onClick={this.onMarkerClick}
            name={'ROYAL LEOPOLD CLUB'}
            position={{ lat: 50.8080459, lng: 4.352025899999944 }}
            icon={{
              url:
                'https://storage.googleapis.com/innobelge-websites.appspot.com/sites/bts/favicon/favicon-96x96.png',
              anchor: new google.maps.Point(32, 32),
              scaledSize: new google.maps.Size(32, 32)
            }}
          />
        </Map>
      </div>
    )
  }
}

const LoadingContainer = props => <div>Fancy loading container!</div>

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDbBsPrzFreCqJTaD3CM4_VUSQ8YAF73rA',
  LoadingContainer: LoadingContainer
})(MapContainer)
