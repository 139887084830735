import { MediaQueries } from 'inno-components'
import { StyleSheet, css } from 'aphrodite'
import Config from '../Config'

const styles = StyleSheet.create({
  section: {
    padding: '1.5em 1.5em',
    [MediaQueries.small]: {
      padding: '2em 4em'
    }
  },
  a: {
    textDecoration: 'none'
  },
  buttonPrimary: {
    color: '#fff',
    padding: '0.5em 2em',
    background: Config.colors.secondary,
    borderRadius: '25px',
    transition: 'all 0.3s ease-in-out',
    ':hover': {
      color: '#fff',
      background: '#d51246',
      textDecoration: 'none'
    }
  },
  buttonSecondary: {
    color: '#fff',
    padding: '0.5em 2em',
    background: Config.colors.primary,
    borderRadius: '25px',
    transition: 'all 0.3s ease-in-out',
    ':hover': {
      color: '#fff',
      background: '#00B3AB',
      textDecoration: 'none'
    }
  },
  buttonThirdary: {
    color: '#fff',
    padding: '0.5em 2em',
    background: Config.colors.thirdary,
    borderRadius: '25px',
    transition: 'all 0.3s ease-in-out',
    ':hover': {
      color: '#fff',
      background: '#00B3AB',
      textDecoration: 'none'
    }
  },
  link: {
    color: 'inherit'
  },
  title: {
    fontSize: '1.4em',
    fontWeight: 800,
    marginBottom: '2em',
    color: Config.colors.primary,
    textAlign: 'center'
  },
  paragraph: {
    fontWeight: 300
  },
  paragraphBordered: {
    display: 'block',
    paddingLeft: '1.5em',
    marginTop: '1.5em',
    borderLeft: `solid 6px ${Config.colors.primary}`
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})

export default styles
